import React, {Component} from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';


const Wrapper = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`

class Loader extends Component {

  render(){
    return <Wrapper>
      <LoadingOutlined style={{
        fontSize: 36
      }}/>
    </Wrapper>;
  }

}

export default Loader;

