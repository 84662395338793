import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Box from "./Box";
import Loader from "./Loader";
import requests from "../requests";
import {Empty, Typography, Button} from "antd";
import renderHTML from "react-render-html";

const {Title, Paragraph, Text} = Typography;

class Wire extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            info: null,
            error: false
        }
    }

    async componentDidMount() {
        const {id} = this.props.match.params;
        const {setStyle} = this.props;
        const r = await requests.get('cashier/wire', {
            id: id
        });
        if (r.status === 200) {
            this.setState({
                loading: false,
                info: r.data
            });
            setStyle(r.data.merchant);
        } else {
            this.setState({
                error: r.error,
                loading: false
            })
        }
    }

    render() {
        const {loading, error, info} = this.state;
        return <Box>
            {loading ? <Loader/> : error ? <Empty>{error}</Empty> : (
                (
                    <div>
                        <div id={'print'}>
                            <Title level={4}>Please, use following requisites to complete Wire Transfer</Title>
                            {renderHTML(info.info)}
                            <Paragraph>Reference: <Text strong>{info.reference}</Text></Paragraph>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            {info.return_url && <Button style={{marginRight: 10}} type={'default'} onClick={() => window.location.href = info.return_url}>Return to merchant</Button>}
                            <Button type={'primary'} onClick={() => window.print()}>Print</Button>
                        </div>
                    </div>
                )
            )}
        </Box>
    }
}

export default withRouter(Wire);