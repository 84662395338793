import React, {Component} from 'react';
import styled from 'styled-components';
import {PageHeader} from "antd";
import 'antd/dist/antd.css';


const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.bodyColor};
  position: relative;
  @media screen and (min-width: 768px) {
    padding-bottom: ${props => props.padding}px;
  }
  
  .ant-page-header {
    border-bottom: 1px solid #e3e3e3;
  }
`;

const Container = styled.div`
    width: 100%;
    max-width: 900px;
    margin: 40px auto;
    position: relative;
`;

const Footer = styled.div`
    position: absolute;
    padding: 20px;
    text-align: center;
    border-top: 1px solid #e3e3e3;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.fontColor};
    @media screen and (max-width: 767px) {
        position: relative;
    }
`;

class Layout extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            footerHeight: 300
        }
    }

    componentDidMount() {
        const addPadding = () => {
            const height = document.getElementById('footer').offsetHeight;
            this.setState({
                footerHeight: height
            });
        }
        setTimeout(addPadding, 500);
    }

    render(){
        const {style} = this.props;
        const {footerHeight} = this.state;
        return (
            <Wrapper bodyColor={style.bodyColor} padding={footerHeight}>
                <PageHeader title={style.title} style={{backgroundColor: style.headerColor}}/>
                <Container>
                    {this.props.children}
                </Container>
                <Footer
                    id={'footer'}
                    backgroundColor={style.footerBackgroundColor}
                    fontColor={style.footerFontColor}
                >{style.footerText}</Footer>
            </Wrapper>
        )
    }

}

export default Layout;