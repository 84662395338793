import styled from "styled-components";

export default styled.div`
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 15px;
    width: 90%;
    margin: 0 auto;
    min-height: 40vh;
    background-color: #fff;
    position: relative;
`;