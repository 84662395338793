import React, {Component} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {message, Modal} from "antd";


class Signature extends Component{

    constructor(props, context) {
        super(props, context);
        this.signature = null;
        this.state = {
            lang: props.lang
        }
    }

    sign = (e) => {
        e.preventDefault();
        const {lang} = this.state;
        const {sign, close} = this.props;
        if (this.signature.isEmpty()){
            message.error('Please put a signature!');
            return false;
        }
        sign(this.signature.getTrimmedCanvas().toDataURL());
        close();
    }

    clear = () => {
        this.signature.clear();
    }

    render(){
        const {show} = this.props;
        return (
            <Modal
                title={null}
                visible={show}
                onOk={this.sign}
                okText={'Sign'}
                cancelText={'Clear'}
                onCancel={this.clear}
                centered={true}
                closable={false}
            >
                <SignatureCanvas ref={(ref) => { this.signature = ref }} penColor='black' canvasProps={{width: '450', height: '200'}} />
            </Modal>
        )
    }

}

export default Signature;