import './App.css';
import React, {Component} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Layout from "./components/Layout";
import {Empty} from "antd";
import Transaction from "./components/Transaction";
import Wire from "./components/Wire";
import CreditCard from "./components/CreditCard";
import ThankYou from "./components/ThankYou";
import Declaration from "./components/Declaration";


class App extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            title: null,
            subTitle: null,
            onBack: false,
            data: null,
            error: false,
            headerColor: '#fff',
            bodyColor: '#f3f3f3',
            footerFontColor: '#000',
            footerBackgroundColor: '#fff',
            footerText: null
        }
    }

    setStyle = (style) => {
        this.setState({
            title: (<a href={style.url}><img src={style.logo} alt={style.name} style={{width: '100%', maxWidth: 200}}/></a>),
            headerColor: style.header_color,
            bodyColor: style.body_color,
            footerFontColor: style.footer_font_color,
            footerBackgroundColor: style.footer_background_color,
            footerText: style.footer_text
        })
    }

    render(){
        const {title, headerColor, bodyColor, footerFontColor, footerBackgroundColor, footerText} = this.state;
        const style = {title, headerColor, bodyColor, footerFontColor, footerBackgroundColor, footerText}
        return (
            <Router basename="/">
                <Switch>
                    <Layout style={style}>
                        <Route exact={true} path="/" render={() => <Empty>Bad Request. Please, try again later.</Empty>}/>
                        <Route exact={true} path={'/:id'} render={() => <Transaction setStyle={this.setStyle} />}/>
                        <Route exact={true} path={'/:id/wire'} render={() => <Wire setStyle={this.setStyle} />}/>
                        <Route exact={true} path={'/:id/cc'} render={() => <CreditCard setStyle={this.setStyle} />}/>
                        <Route exact={true} path={'/:id/thank_you'} render={() => <ThankYou setStyle={this.setStyle} />}/>
                        <Route exact={true} path={'/:id/dod'} render={() => <Declaration setStyle={this.setStyle} />}/>
                    </Layout>
                </Switch>
            </Router>
        )
    }

}

export default App;
