import axios from 'axios';

const base = 'https://crex.gcg.technology/';

class requests {

    static get = (path, params= {}, headers={}) => {
        const qsList = [];
        for (const [key, value] of Object.entries(params)) {
            qsList.push(`${key}=${value}`);
        }
        const qs = qsList.join('&');
        return axios({
            method: 'get',
            url: `${base}/${path}?${qs}`,
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return {
                error: error.response.data.error,
                data: error.response.data,
                status: error.response.status
            };
        });
    }

    static post = (path, params = {}, headers={}) => {
        return axios({
            method: 'post',
            url: `${base}/${path}`,
            data: params
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return {
                error: error.response.data.error,
                data: error.response.data,
                status: error.response.status
            };
        });
    }

}

export default requests;