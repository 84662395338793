import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Box from "./Box";
import Loader from "./Loader";
import requests from "../requests";
import {Empty, Typography, Button, message} from "antd";
import styled from 'styled-components';
import CreditCardInput from "react-credit-card-input";
import cards from '../assets/img/cards.png';
import pci from '../assets/img/pci.png';

const {Title, Paragraph} = Typography;

const ButtonWrap = styled.div`
    margin: 20px auto;
`;

const Wrapper = styled.div`
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
`;

const Cards = styled.div`
    background: url("${cards}");
    width: 200px;
    height: 70px;
    background-size: contain;
    bottom: 15px;
    position: absolute;
`;

const PCI = styled.div`
    background: url("${pci}");
    width: 182px;
    height: 70px;
    background-size: contain;
    bottom: 15px;
    right: 15px;
    position: absolute;
`;

class CreditCard extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            info: null,
            error: false,
            number: localStorage.getItem('card_first_6') ? localStorage.getItem('card_first_6') : null,
            exp: null,
            cvv: null,
            cardError: false,
            buttonLoad: false,
            paymentCompleted: false
        }
    }

    async componentDidMount() {
        const {id} = this.props.match.params;
        const {setStyle} = this.props;
        const r = await requests.get('cashier/cc', {
            id: id
        });
        if (r.status === 200) {
            this.setState({
                loading: false,
                info: r.data
            });
            setStyle(r.data.merchant);
        } else {
            this.setState({
                error: r.error,
                loading: false
            })
        }
    }

    handleChange = (key, val) => {
        this.setState({
            cardError: false,
            [key]: val
        })
    }

    error = () => {
        this.setState({
            cardError: true
        })
    }

    pay = async () => {
        const {number, exp, cvv, cardError} = this.state;
        const {id} = this.props.match.params;
        if (!number || !exp || !cvv || cardError) {
            message.error('Please, input your credit card details!')
            return null;
        }
        this.setState({
            buttonLoad: true
        });
        const r = await requests.post('cashier/process_card', {
            number: number,
            exp: exp,
            cvv: cvv,
            id: id
        });
        if (r.status === 200) {
            // console.log(r.data)
            if (r.data.redirect) {
                window.location.href = r.data.redirect
            } else {
                this.setState({
                    paymentCompleted: true
                })
            }
        } else {
            message.error(r.error);
            this.setState({
                buttonLoad: false
            });
        }
    }

    render() {
        const {loading, error, info, number, exp, cvv, cardError, buttonLoad, paymentCompleted} = this.state;
        return <Box>
            {loading ? <Loader/> : error ? <Empty>{error}</Empty> : (
                (
                    <Wrapper>
                        {paymentCompleted ? (
                            <Title level={4}>Thank you for payment. We will notify about result via e-mail.</Title>
                        ) : (
                            <div>
                                <Paragraph>Please, complete payment</Paragraph>
                                <Paragraph strong>{info.reference}</Paragraph>
                                <Title level={4}>{info.amount}</Title>
                                <CreditCardInput
                                    cardNumberInputProps={{
                                        value: number,
                                        onChange: e => this.handleChange('number', e.target.value),
                                        onError: this.error
                                    }}
                                    cardExpiryInputProps={{
                                        value: exp,
                                        onChange: e => this.handleChange('exp', e.target.value),
                                        onError: this.error
                                    }}
                                    cardCVCInputProps={{
                                        value: cvv,
                                        onChange: e => this.handleChange('cvv', e.target.value),
                                        onError: this.error
                                    }}
                                    fieldClassName="input"
                                />
                                <ButtonWrap>
                                    <Button loading={buttonLoad} onClick={this.pay} disabled={cardError} type={'primary'}>Pay</Button>
                                </ButtonWrap>
                            </div>
                        )}

                    </Wrapper>
                )
            )}
            <Cards/>
            <PCI/>
        </Box>
    }
}

export default withRouter(CreditCard);