import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import requests from "../requests";
import styled from 'styled-components';
import Loader from "./Loader";
import {
    InputNumber,
    Empty,
    Typography,
    Steps,
    Radio,
    Button,
    Form,
    message,
    Input,
    Select,
    Row,
    Col,
    Modal, Checkbox
} from "antd";
import {
    BankOutlined,
    CreditCardOutlined,
    ExclamationCircleOutlined,
    CopyrightOutlined,
    AppstoreOutlined
} from '@ant-design/icons';
import Box from "./Box";
import cc from '../assets/img/cc.png';

const {Title, Paragraph, Text} = Typography;
const {Step} = Steps;
const {Option} = Select;

const StepContentWrapper = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
    min-height: 200px;
    .ant-radio-group-large .ant-radio-button-wrapper {
        height: auto;
        padding: 15px;
        line-height: 1;
        margin: 10px;
        min-width: 150px;
        text-align: center;
    }
`;

const StepContent = styled.div`
    padding: 20px 0;
    position: relative;
`;

const StepAction = styled.div`
    text-align: center;
`;

class Transaction extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            data: null,
            loading: true,
            error: false,
            chosenMethod: 'cc',
            currentStep: 0,
            stepTwoContent: null,
            paymentOptions: [],
            optionsLoading: true,
            selectedOption: 0,
            redirecting: false,
            buttonLoading: false,
            currencies: [
                'EUR',
                'USD',
                'GBP',
                'CHF',
                'CAD',
                'ZAR'
            ],
            exchanger: 0,
            termsAccepted: false,
            showWarningModal: false
        }
    }

    next = () => {
        const currentStep = this.state.currentStep + 1;
        this.setState({ currentStep });
    }

    prev = () => {
        const currentStep = this.state.currentStep - 1;
        this.setState({ currentStep });
    }

    stepTwo = async () => {
        const {chosenMethod, data, currencies} = this.state;
        this.setState({
            stepTwoContent: <Loader/>
        });
        let r = null;
        switch (chosenMethod){
            case 'wire':
                r = await requests.get('utils/country_list');
                if (r.status === 200) {
                    this.setState({
                        stepTwoContent: (
                            <Form
                                style={{margin: '15px 0'}}
                                layout={'vertical'}
                                initialValues={{
                                    account_holder_name: localStorage.getItem('account_holder_name') ? localStorage.getItem('account_holder_name') : `${data.first_name} ${data.last_name}`,
                                    account_currency: localStorage.getItem('account_currency') ? localStorage.getItem('account_currency') : data.currency,
                                    account_holder_address: localStorage.getItem('account_holder_address') ? localStorage.getItem('account_holder_address') : data.address,
                                    bank_account_number: localStorage.getItem('bank_account_number') ? localStorage.getItem('bank_account_number') : null,
                                    swift_code: localStorage.getItem('swift_code') && localStorage.getItem('swift_code') !== 'null' ? localStorage.getItem('swift_code') : null,
                                    bank_country: localStorage.getItem('bank_country') ? localStorage.getItem('bank_country') : data.country,
                                }}
                                onFinish={this.stepThree}
                                id={'step-two-form'}
                            >
                                <Title level={4}>Please, fill up bank details</Title>
                                <Row gutter={16}>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            label={'Account holder name'}
                                            name="account_holder_name"
                                            rules={[{ required: true, message: 'Please input your account holder name!' }]}
                                        >
                                            <Input autoComplete="new-password" />
                                        </Form.Item>
                                        <Form.Item
                                            style={{
                                                marginTop: 90
                                            }}
                                            label={'Account holder address'}
                                            name="account_holder_address"
                                            rules={[{ required: true, message: 'Please input your account holder address!' }]}
                                        >
                                            <Input autoComplete="new-password" />
                                        </Form.Item>
                                        <Form.Item
                                            label={'Bank account number'}
                                            name="bank_account_number"
                                            rules={[{ required: true, message: 'Please input your bank account number!' }]}
                                        >
                                            <Input autoComplete="new-password" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            style={{
                                                textAlign: 'left'
                                            }}
                                            name={'account_currency'}
                                            label={'Account currency'}
                                            rules={[{ required: true, message: 'Please select account currency!' }]}
                                            extra={'If the account currency differs from the trading platform currency, the rate is determined at the time the funds are credited.'}
                                        >
                                            <Select
                                                placeholder={'Select currency'}
                                                autoComplete="new-password"
                                                style={{textAlign: 'left'}}
                                            >
                                                {currencies.map((v, i) => {
                                                    return <Option key={i} value={v}>{v}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name={'bank_country'}
                                            label={'Bank country'}
                                            rules={[{ required: true, message: 'Please select bank country from the list!' }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder={'Select country'}
                                                autoComplete="new-password"
                                                style={{textAlign: 'left'}}
                                            >
                                                {r.data.map((v, i) => {
                                                    return <Option key={i} value={v.iso}>{v.name}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label={'SWIFT code'}
                                            name="swift_code"
                                        >
                                            <Input autoComplete="new-password" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    })
                } else {
                    message.error('Unexpected error. Please contact support');
                }
                break;
            case 'cc':
                this.setState({
                    stepTwoContent: (
                        <Form
                            style={{margin: '15px 0'}}
                            layout={'vertical'}
                            initialValues={{
                                card_first_6: localStorage.getItem('card_first_6') ? localStorage.getItem('card_first_6') : null,
                            }}
                            onFinish={this.stepThree}
                            id={'step-two-form'}
                        >
                            <Paragraph>
                                Please, enter <Text strong>BIN code</Text> (first six digits) of your credit card so we can define the most suitable payment options for you:
                            </Paragraph>
                            <Form.Item
                                label={'BIN Code'}
                                name="card_first_6"
                                rules={[{ required: true, message: 'Please input BIN code of your credit card!' }]}
                                style={{
                                    width: '100%',
                                    maxWidth: 300,
                                    margin: '0 auto'
                                }}
                            >
                                <InputNumber
                                    style={{width: '100%'}}
                                    min={100000}
                                    max={999999}
                                    autoComplete="new-password"
                                    placeholder={'407441'}
                                />
                            </Form.Item>
                            <div style={{
                                width: '100%',
                                maxWidth: 300,
                                margin: '0 auto'
                            }}>
                                <img src={cc} alt="Credit Card BIN Example" style={{
                                    width: '100%',
                                    margin: '0 auto'
                                }}/>
                            </div>
                        </Form>
                    )
                })
                break;
            case 'crypto':
                r = await requests.get('bitpay/exchangers');
                if (r.status === 200) {
                    this.setState({
                        stepTwoContent: (
                            <Form
                                style={{margin: '15px 0'}}
                                layout={'vertical'}
                                onFinish={this.stepThree}
                                id={'step-two-form'}
                            >
                                <Paragraph>According to our AML policy we must ask you, which exchanger did you use in order to get your BTC?</Paragraph>
                                <Form.Item
                                    label={'Exchanger'}
                                    name="exchanger"
                                    rules={[{ required: true, message: 'Please choose an exchanger from the list!' }]}
                                    style={{
                                        width: '100%',
                                        maxWidth: 300,
                                        margin: '0 auto'
                                    }}
                                >
                                    <Select
                                        showSearch
                                        onChange={e => this.setState({exchanger: e})}
                                        style={{ width: '100%', marginBottom: '20px' }}
                                        placeholder="Search an exchanger"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {r.data.map((v, i) => {
                                            return <Option key={i} value={v.id}>{v.label}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Form>
                        )
                    });
                } else {
                    message.error('Unexpected error happened, please contact support');
                }
                // await this.stepThree({});
                break;
            case 'other':
                this.setState({
                    stepTwoContent: (
                        <Form
                            style={{margin: '15px 0'}}
                            layout={'vertical'}
                            onFinish={this.stepThree}
                            id={'step-two-form'}
                        >
                            <Paragraph>Click "Next" to proceed</Paragraph>
                        </Form>
                    )
                })
                break;
        }

        this.next();
    }

    stepThree = async (values) => {
        this.setState({
            buttonLoading: true
        });
        const {data, chosenMethod} = this.state;
        const SEPA = ['FI', 'AD','AT','PT','BE','CY','EE','FI','FR','DE','GI','GR','IE','IT','LV','LT','LU','PT','MT', 'MC','NL','PT','SM','SK','SI','ES'];
        for (const [key, value] of Object.entries(values)) {
            localStorage.setItem(key, String (value));
        }
        const params = Object.assign(values, {
            method: chosenMethod,
            session: data.session,
            id: data.id
        })
        const r = await requests.post('cashier/update_info', params);
        if (r.status === 200) {
            this.setState({
                optionsLoading: false,
                paymentOptions: r.data
            });

            const conditions = [
                chosenMethod === 'wire',
                data.amount < 1000,
                !SEPA.includes(values.bank_country)
            ];
            const checker = arr => arr.every(v => v === true);
            if (checker(conditions)) Modal.info({
                title: 'Please, pay attention!',
                content: 'The minimum deposit for SWIFT transfers in USD currency is 1000 USD.',
                async onOk() {},
            });

            this.next();
        } else {
            message.error(r.error)
        }
        this.setState({
            buttonLoading: false
        });

        if (chosenMethod === 'crypto') {
            this.setState({showWarningModal: true});
        }
    }

    async componentDidMount() {
        const {id} = this.props.match.params;
        const {setStyle} = this.props;
        const r =  await requests.get('cashier/info', {
            id: id
        });
        if (r.status === 200) {
            this.setState({
                data: r.data,
                chosenMethod: r.data.available_options[0]
            });
            setStyle(r.data.merchant);
        } else {
            this.setState({error: r.error});
        }
        this.setState({loading: false});
    }

    redirect = async () => {
        const {data, selectedOption, paymentOptions, exchanger} = this.state;

        const process = async () => {
            this.setState({
                redirecting: true
            });
            const r = await requests.post('cashier/redirect', {
                session: data.session,
                id: data.id,
                option: paymentOptions[selectedOption].id,
                exchanger: exchanger
            });
            if (r.status === 200) {
                window.location.href = r.data.redirect;
            } else {
                message.error(r.error);
                this.setState({redirecting: false});
            }
        }

        if (paymentOptions[selectedOption].popup) {
            Modal.info({
                title: 'Please, pay attention!',
                content: paymentOptions[selectedOption].popup,
                async onOk() {
                    await process();
                },
            });
        } else {
            await process();
        }

    }

    render() {
        const {
            error,
            loading,
            data,
            chosenMethod,
            currentStep,
            stepTwoContent,
            optionsLoading,
            selectedOption,
            paymentOptions,
            redirecting,
            buttonLoading
        } = this.state;
        console.log(data)
        let content;
        if (loading) content = <Loader/>;
        else if (error) content = <Empty>{error}</Empty>;
        else {
            const radioStyle = {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
            };
            const steps = [
                {
                    title: 'Choose method',
                    content: (
                        <StepContentWrapper>
                            <Radio.Group defaultValue={chosenMethod} size={'large'} onChange={async (e) => {
                                await this.setState({
                                    chosenMethod: e.target.value
                                });
                            }}>
                                {data.available_options.includes('cc') && <Radio.Button value={'cc'}>
                                    <CreditCardOutlined  style={{
                                        display: 'block',
                                        fontSize: 48,
                                        margin: 5
                                    }} />
                                    Credit Card
                                </Radio.Button>}
                                {data.available_options.includes('wire') && <Radio.Button value={'wire'}>
                                    <BankOutlined style={{
                                        display: 'block',
                                        fontSize: 48,
                                        margin: 5
                                    }} />
                                    Wire Transfer
                                </Radio.Button>}
                                {data.available_options.includes('crypto') && <Radio.Button value={'crypto'}>
                                    <CopyrightOutlined style={{
                                        display: 'block',
                                        fontSize: 48,
                                        margin: 5
                                    }} />
                                    Cryptocurrency
                                </Radio.Button>}
                                {data.available_options.includes('other') && <Radio.Button value={'other'}>
                                    <AppstoreOutlined style={{
                                        display: 'block',
                                        fontSize: 48,
                                        margin: 5
                                    }} />
                                    Other
                                </Radio.Button>}
                            </Radio.Group>
                        </StepContentWrapper>
                    )
                },
                {
                    title: 'Complete information',
                    content: (
                        <StepContentWrapper>{stepTwoContent}</StepContentWrapper>
                    )
                },
                {
                    title: 'Choose option',
                    content: (
                        <StepContentWrapper>
                            {optionsLoading ? <Loader/> : paymentOptions.length > 0 ? (
                                (
                                    <div style={{width: '100%', maxWidth: 300, margin: '20px auto', textAlign: "left"}}>
                                        <Radio.Group onChange={async (e) => {
                                            await this.setState({
                                                selectedOption: e.target.value
                                            });
                                        }} value={selectedOption}>
                                            {paymentOptions.map((v, i) => {
                                                return (
                                                    <Radio key={i} style={radioStyle} value={i}>
                                                        {v.name}
                                                    </Radio>
                                                )
                                            })}
                                        </Radio.Group>
                                    </div>
                                )
                            ) : <Empty>Payment options not found. Please, try different method.</Empty>}
                        </StepContentWrapper>
                    )
                }
            ];
            content = (
                <div>
                    <div style={{textAlign: 'center'}}>
                        <Paragraph>{data.order_description}</Paragraph>
                        <Title level={4}>{data.amount} {data.currency}</Title>
                        <Steps current={currentStep}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <StepContent>
                            {steps[currentStep].content}
                        </StepContent>
                        <StepAction>
                            {currentStep > 0 && (
                                <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                                    Previous
                                </Button>
                            )}
                            {currentStep === 0  && (
                                <Button type="primary" onClick={this.stepTwo}>
                                    Next
                                </Button>
                            )}
                            {currentStep === 1  && (
                                <Button type="primary" htmlType={'submit'} form={'step-two-form'} loading={buttonLoading}>
                                   Next
                                </Button>
                            )}
                            {currentStep === steps.length - 1 && (
                                <Button disabled={paymentOptions.length === 0} loading={redirecting} type="primary" onClick={this.redirect}>
                                    Pay
                                </Button>
                            )}
                        </StepAction>
                    </div>
                </div>
            )
        }
        const {termsAccepted, showWarningModal} = this.state;
        return (
            <Box>
                {content}
                <Modal
                    title={'Warning!'}
                    icon={<ExclamationCircleOutlined/>}
                    onOk={this.redirect}
                    onCancel={() => {this.setState({showWarningModal: false}); this.prev();}}
                    okButtonProps={{disabled: !termsAccepted}}
                    visible={showWarningModal}

                >
                    <div>
                        <Paragraph>Pay attention to the following terms and conditions:</Paragraph>
                        <Paragraph>1) the wallet is active only 24 hours, do not use the expired wallet</Paragraph>
                        <Paragraph>2) do not use the same wallet twice</Paragraph>
                        <Checkbox
                            onChange={() => this.setState({termsAccepted: !termsAccepted})}
                        >I have read and agree to the terms and conditions above</Checkbox>
                    </div>
                </Modal>
            </Box>
        )
    }

}

export default withRouter(Transaction);