import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Empty, Typography} from "antd";
import Box from "./Box";
import Loader from "./Loader";
import {
    SmileOutlined
} from '@ant-design/icons';
import requests from "../requests";

const {Title, Paragraph,Text} = Typography;

class ThankYou extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            error: false
        }
    }

    async componentDidMount() {
        const {id} = this.props.match.params;
        const {setStyle} = this.props;
        const r =  await requests.get('cashier/merchant', {
            id: id
        });
        if (r.status === 200) {
            setStyle(r.data);
        } else {
            this.setState({
                error: r.error
            })
        }
        this.setState({
            loading: false
        });
    }

    render() {
        const {loading, error} = this.state;
        let content;
        if (loading) content = <Loader/>;
        else if (error) content = <Empty>{error}</Empty>;
        else content = (
            <div style={{textAlign: 'center'}}>
                <SmileOutlined style={{fontSize: 128}} />
                <Title level={1}>Thank You</Title>
                <Title level={4}>We have received your request</Title>
                <Paragraph>Our managers will contact you in order to provide you with bank transfer details</Paragraph>
                <Text>Please stay in touch</Text>
            </div>
        )
        return <Box>
            {content}
        </Box>
    }
}

export default withRouter(ThankYou);