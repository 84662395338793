import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Box from "./Box";
import Loader from "./Loader";
import requests from "../requests";
import {Empty, Typography, Button, message, Row, Col, Form, Input, Select, notification} from "antd";
import styled from 'styled-components';
import Signature from "./Signature";

const {Paragraph} = Typography;
const {Option} = Select;

const Wrapper = styled.div`
    text-align: center;
`;


class Declaration extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            info: null,
            error: false,
            buttonLoad: false,
            showOtherIncome: false,
            signatureModal: false
        }
    }

    async componentDidMount() {
        const {id} = this.props.match.params;
        const {setStyle} = this.props;
        const r = await requests.get('cashier/dod_fields', {
            id: id
        });
        if (r.status === 200) {
            this.setState({
                loading: false,
                info: r.data
            });
            setStyle(r.data.merchant);
        } else {
            this.setState({
                error: r.error,
                loading: false
            })
        }
    }

    proceed = async (values) => {
        this.setState({
            buttonLoad: true,
        });
        Object.assign(values, {
            id: this.props.match.params.id
        });
        console.log(values);
        const r = await requests.post('cashier/generate_dod', values);
        if (r.status === 200) {
            const {info} = this.state;
            info.signature_file = r.data.url;
            this.setState({
                info: info
            });
        } else {
            this.setState({
                buttonLoad: false
            });
            message.error('Unexpected error');
        }
    }
    sign = async (url) => {
        const {info} = this.state;
        const {signature_file} = info;
        const r = await requests.post('cashier/sign_dod', {
            id: this.props.match.params.id,
            signature: url
        });
        if (r.status === 200) {
            info.signature_file = '';
            await this.setState({info: info});
            info.signature_file = signature_file;
            info.signature_done = true;
            this.setState({info: info});
            notification.success({
                message: 'Thank you!',
                description: 'Signature was done successfully. Your account will be funded soon.',
                duration: 7
            });
            if (r.data.redirect) setTimeout(() => {
               window.location.href = r.data.redirect;
            }, 3000);

        } else {
            message.error('Unexpected error')
        }
    }


    render() {
        const {loading, error, info, buttonLoad, showOtherIncome, signatureModal} = this.state;
        const sources = [
            'Salary (including self-employed)',
            'Retirement income',
            'Sale of investments/liquidation of investment portfolio',
            'Sale of property',
            'Company profits/ earnings/ dividends',
            'Loan',
            'Gift',
            'Other source (please specify)',
        ]
        const months = [];
        const years = [];
        for (let m = 1; m < 13; m++) {
            if (m < 10) {
                months.push(`0${m}`);
            } else {
                months.push(m);
            }
        }
        for (let y = 2020; y < 2035; y++) years.push(y);

        return <Box style={{width: '100%'}}>
            {loading ? <Loader/> : error ? <Empty>{error}</Empty> : info.signature_file ? (
                <div>
                    <iframe width={'100%'} height={600} src={info.signature_file} frameBorder="0"></iframe>
                    <div style={{textAlign: 'center'}}>
                        {!info.signature_done && <Button onClick={() => this.setState({
                            signatureModal: true
                        })} shape={'round'} type={'primary'}>Sign</Button>}
                    </div>
                    <Signature
                        show={signatureModal}
                        close={() => this.setState({signatureModal: false})}
                        sign={this.sign}
                    />
                </div>
            ) : (
                <Wrapper>
                    <Paragraph>To complete the payment and funds have been credited to your account, please provide the following information:</Paragraph>
                    <Form
                        style={{margin: '15px 0'}}
                        layout={'vertical'}
                        initialValues={info.fields}
                        onFinish={this.proceed}
                    >
                        <Form.Item
                            label={'Full name'}
                            name="name"
                            rules={[{ required: true, message: 'Please enter your name!' }]}
                        >
                            <Input autoComplete="new-password" disabled={true} />
                        </Form.Item>
                        <Form.Item
                            label={'Address'}
                            name="address"
                            rules={[{ required: true, message: 'Please specify your address!' }]}
                        >
                            <Input autoComplete="new-password" disabled={true} />
                        </Form.Item>
                        <Form.Item
                            label={'Citizenship'}
                            name="citizenship"
                            rules={[{ required: true, message: 'Please specify your citizenship!' }]}
                        >
                            <Input placeholder={'Specify your citizenship...'} autoComplete="new-password" disabled={info.fields.citizenship}  />
                        </Form.Item>
                        <Form.Item
                            label={'Trading account number'}
                            name="trading_account_number"
                            rules={[{ required: true, message: 'Please specify trading account number!' }]}
                        >
                            <Input autoComplete="new-password" disabled={info.fields.trading_account_number} />
                        </Form.Item>
                        <Form.Item
                            name={'income_source'}
                            label={'Source of income'}
                            rules={[{ required: true, message: 'Please select source of income!' }]}
                        >
                            <Select
                                placeholder={'Please select'}
                                autoComplete="new-password"
                                style={{textAlign: 'left'}}
                                onChange={(e) => {
                                    this.setState({
                                        showOtherIncome: e === 'Other source (please specify)'
                                    });
                                }}
                            >
                                {sources.map((v, i) => {
                                    return <Option key={i} value={v}>{v}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        {showOtherIncome && <Form.Item
                            label={false}
                            name="other_income_source"
                            rules={[{ required: true, message: 'Please specify source of income!' }]}
                        >
                            <Input placeholder={'Specify your source of income...'} autoComplete="new-password" />
                        </Form.Item>}
                        {info.type === 'cc' && <Row gutter={16}>
                            <Col xs={24}>
                                <Form.Item
                                    label={'Credit card cardholder name'}
                                    name="cardholder_name"
                                    rules={[{ required: true, message: 'Please specify credit card\'s cardholder name!' }]}
                                    extra={'Please enter your name in this field, just like on your credit card. If your credit card does not have a name, enter your name in this field as you would in your account.'}
                                >
                                    <Input autoComplete="new-password" />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label={'Credit card last four digits'}
                                    name="last4"
                                    rules={[{ required: true, message: 'Please specify credit card\'s last 4 digits!' }]}
                                >
                                    <Input autoComplete="new-password" disabled={info.fields.last4} />
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    name={'exp_month'}
                                    label={<Paragraph ellipsis>Credit card expiration month</Paragraph>}
                                    rules={[{ required: true, message: 'Please select expiration month!' }]}
                                >
                                    <Select
                                        placeholder={'Select month'}
                                        autoComplete="new-password"
                                        style={{textAlign: 'left'}}
                                    >
                                        {months.map((v, i) => {
                                            return <Option key={i} value={v}>{v}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    name={'exp_year'}
                                    label={<Paragraph ellipsis>Credit card expiration year</Paragraph>}
                                    rules={[{ required: true, message: 'Please select expiration year!' }]}
                                >
                                    <Select
                                        placeholder={'Select year'}
                                        autoComplete="new-password"
                                        style={{textAlign: 'left'}}
                                    >
                                        {years.map((v, i) => {
                                            return <Option key={i} value={v}>{v}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>}
                        <Button htmlType={'submit'} type={'primary'} block shape={'round'} loading={buttonLoad}>Proceed</Button>
                    </Form>
                </Wrapper>
            )}
        </Box>
    }
}

export default withRouter(Declaration);